(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@pixi/utils"), require("@pixi/math"), require("@pixi/core"), require("lodash/pull"), require("@pixi/display"), require("lodash/mapKeys"));
	else if(typeof define === 'function' && define.amd)
		define(["@pixi/utils", "@pixi/math", "@pixi/core", "lodash/pull", "@pixi/display", "lodash/mapKeys"], factory);
	else if(typeof exports === 'object')
		exports["pixi-live2d-display"] = factory(require("@pixi/utils"), require("@pixi/math"), require("@pixi/core"), require("lodash/pull"), require("@pixi/display"), require("lodash/mapKeys"));
	else
		root["PIXI"] = root["PIXI"] || {}, root["PIXI"]["live2d"] = factory(root["PIXI"]["utils"], root["PIXI"], root["PIXI"], root["lodash/pull"], root["PIXI"], root["lodash/mapKeys"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__5__, __WEBPACK_EXTERNAL_MODULE__17__, __WEBPACK_EXTERNAL_MODULE__19__, __WEBPACK_EXTERNAL_MODULE__20__, __WEBPACK_EXTERNAL_MODULE__24__) {
return 